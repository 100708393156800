import variables from "./variables"

function getProjectElements(project) {
  let content = document.querySelector(".single__project__wrapper")
  let i = 1

  let h = project.media
  h.sort((a, b) => {
    return a.orderNr - b.orderNr
  })

  h.forEach((element) => {
    if (element.online) {
      let e = document.createElement("div")
      e.classList.add("single__element")
      e.classList.add(`single__element-${i}`)

      if (element.type == "photo") {
        // PHOTO Content:
        let img = document.createElement("img")
        img.src = element.webp_image
        e.appendChild(img)
      } else {
        // VIDEO Content:
        let pullZone = variables().videoPullZone
        let vUrl = `${pullZone}${element.videoID}/play_720p.mp4`
        let vURL_fallback = `${pullZone}${element.videoID}/play_480p.mp4`
        let vPoster = `${pullZone}${element.videoID}/thumbnail.jpg`
        let vPreview = `${pullZone}${element.videoID}/preview.webp`
        e.innerHTML += `<div class="video__container"><video loop playsinline muted height="auto" width="100%" id="${element.videoID}" poster="${vPoster}">
        <source type="video/mp4" src="${vUrl}"><source type="video/mp4" src="${vURL_fallback}"></video></div>`
        e.classList.add("show-video-ctrl")
      }
      content.appendChild(e)
      if (element.type == "video") {
        document
          .getElementById(`${element.videoID}`)
          .parentElement.addEventListener("click", (e) => {
            if (e.target.parentElement.classList.contains("show-video-ctrl")) {
              e.target.parentElement.classList.add("hide-video-ctrl")
              console.log(e.target.children[0])
              e.target.children[0].play()
              window.setTimeout(() => {
                e.target.children[0].controls = true
                e.target.children[0].muted = false
                e.target.children[0].volume = 0.01
              }, 300)
            }
          })
      }
      i++
    }
  })
}

// https://vz-7d5997c8-803.b-cdn.net/bf477c3d-32aa-419a-b8a8-7677ca0e000d/thumbnail.jpg
// https://vz-7d5997c8-803.b-cdn.net/bf477c3d-32aa-419a-b8a8-7677ca0e000d/preview.webp
export default getProjectElements
