import variables from "./variables"
const axios = require("axios")

async function getPages() {
  const data = variables()

  let firstPage
  // Get all Pages of User:
  try {
    const response = await axios.get(data.pageUrl)
    let d = ""
    let h = true
    let onlinePages = response.data.filter((page) => {
      return page.status
    })
    firstPage = onlinePages[0]
    onlinePages.forEach((page) => {
      let p = page.name.split(" ")
      let i
      let p_1 = p[0] + " " + p[1]
      let p_2 = ""
      for (i = 2; i < p.length; i++) {
        p_2 += p[i]
        p_2 += " "
      }
      let pageName
      if (p_2 != "") {
        pageName = `<div class="link-underline">${p_1}</div><div class="link-underline">${p_2}</div>`
      } else {
        pageName = `<div class="link-underline">${p_1}</div>`
      }

      d += `<div class="page-link" data-page-ID="${page.id}" data-active-page="${h}">${pageName}</div>`
      h = false
    })
    return {
      d: d,
      firstPage: firstPage,
      pages: onlinePages,
    }
  } catch (error) {
    console.error(error)
  }
}

export default getPages
