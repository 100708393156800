import variables from "./variables"

function getSingleElement(data) {
  let helper
  let output
  let exportedData
  // check if project is online
  if (data.status == true) {
    // check what is main image
    let mainImageProject = data.media.filter((element) => {
      return element.isMainImage
    })
    let m = mainImageProject[0]

    //console.log(m)

    // check if photo or video:
    if (m.type == "video") {
      helper = "video"

      let videoID = m.videoID
      let pullZone = variables().videoPullZone

      let videoURL = `${pullZone}${videoID}/play_720p.mp4`
      let videoURL_2 = `${pullZone}${videoID}/play_480p.mp4`

      //let c = await checkLink(videoURL, videoURL_2, output)
      //console.log(c)
      output = `
          <video autoplay muted loop playsinline height="auto" width="100%">
            <source type="video/mp4" src="${videoURL}">
            <source type="video/mp4" src="${videoURL_2}">
            </video>`
    }

    // generate photo element (display the first image)
    if (m.type == "photo") {
      helper = "photo"
      output = `<img src="${m.webp_image}">`
    }

    // get Description
    let desc = data.description

    // get Metadata
    let meta
    if (data.year) {
      meta = data.client + " // " + data.name + " // " + data.year
    } else {
      meta = data.client + " // " + data.name
    }

    // get Layout
    let layout = data.layout

    // console.log(output)

    exportedData = {
      projectId: data.id,
      desc: desc,
      output: output,
      meta: meta,
      layout: layout,
    }
  }

  // generate video element (only one video available)

  return exportedData
}

export default getSingleElement
