function variables() {
  const data = {
    userID: "2",
    domain: "backend.frederiknebas.com",
  }

  let pageUrl = `https://${data.domain}/api/pages/?userID=${data.userID}`
  let projectsID = `https://${data.domain}/api/projects/?userID=${data.userID}`
  let projectPage = `https://${data.domain}/api/projects/`

  data.pageUrl = pageUrl
  data.projectURL = projectsID
  data.projectPageURL = projectPage

  data.videoPullZone = "https://vz-7d5997c8-803.b-cdn.net/"

  return data
}

export default variables
