function setProjectBG(colorName) {
  switch (colorName) {
    case "white":
      return "#fff"
      break
    case "black":
      return "#000"
      break
    case "violet":
      return "#aca1cc"
      break
    case "pink":
      return "#f30a8d"
      break
    case "coral":
      return "#f2878d"
      break
    case "yellow":
      return "#f6c705"
      break
  }
}

export default setProjectBG
