import "./styles/main.scss"

// IMPORT PACKAGES
import zenscroll from "zenscroll"
import Flickity from "flickity"
import Hammer from "hammerjs"

// IMPORT CUSTOM FUNCTIONS
import getPages from "./js/getPages"
import getProjects from "./js/getProjects"
import getSingleElement from "./js/getSingleElement"
import getProject from "./js/getProject"
import setProjectBG from "./js/setProjectBGColor"
import getProjectElements from "./js/getProjectElements"

// IMAGES
import introTwoIMG from "./assets/img/Anna-Marina-Roth_VW_Josef-Hoelzl.jpg"
import introMashaIMG from "./assets/img/Anna-Marina-Roth_Portrait.jpg"
import portraitMashaIMG from "./assets/img/masha-portrait.jpg"

// SET IMAGES
const intro2img = document.getElementById("intro-2-img")
intro2img.src = introTwoIMG
const introMashaimg = document.getElementById("intro-masha")
introMashaimg.src = introMashaIMG
const portraitImg = document.getElementById("portrait-img")
portraitImg.src = portraitMashaIMG

let navOpen = document.querySelector(".navigation-burger")
let navClose = document.querySelector(".navigation-close")
let navContent = document.getElementById("main-navigation")

navOpen.addEventListener("click", openNavigation)

function openNavigation() {
  navContent.classList.add("menu-open")
  navOpen.style.display = "none"
  navClose.style.display = "block"
  navClose.addEventListener("click", closeMenu)
  document.querySelectorAll(".menu-item").forEach((item) => {
    item.addEventListener("click", closeMenu)
  })
}

function closeMenu() {
  navClose.style.display = "none"
  navOpen.style.display = "block"
  navContent.classList.remove("menu-open")
}

// CHECK IF ITS MOBILE
let mobile__view = false
let tabletWith = 1100

function isMobile() {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  let test = regex.test(navigator.userAgent)

  let test_2
  if (navigator.maxTouchPoints > 1) {
    console.log("case 1: ", navigator.maxTouchPoints)
    test_2 = true
  } else {
    console.log("case 2: ", navigator.maxTouchPoints)
    test_2 = false
  }
  return {
    test,
    test_2,
  }
}

let t_1 = isMobile().test
let t_2 = isMobile().test_2

if (t_1 || t_2) {
  console.log("Mobile device detected")
  mobile__view = true
} else {
  console.log("Desktop device detected")
  mobile__view = false
}

// DISABLE RIGHT MOUSE CLICK
document.addEventListener("contextmenu", (event) => event.preventDefault())

window.onload = () => {
  zenscroll.setup(null, 0)
}
// Alternative to load event
document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    console.log("loading complete")

    window.setTimeout(function () {
      // Show Page
      console.log("show Page")
      let loader = document.querySelector(".loader")

      loader.classList.add("loading-hide")
    }, 300)
  }
}

// SET GALLERY MENU ITEMS
let pages = await getPages()
let pageWrapper = document.querySelector(".page-link-container")
pageWrapper.innerHTML += pages.d

// // Activate Event Listener on Project Pages
document.querySelectorAll(".page-link").forEach((link) => {
  link.addEventListener("click", changePage, false)
})

// GET PROJECTS FOR THE FIRST PAGE
let projectsFirstPage = await getProjects(pages.firstPage)

// Filter Projects for Status = ONLINE
let projectsFirstPageFiltered = projectsFirstPage.filter((project) => {
  return project.status == true
})

// FILL CONTENT OF FIRST PAGE
// let playgroundElements = document.querySelectorAll(".playground-element")

let playground = document.querySelector(".playground")

//console.log(projectsFirstPageFiltered)

// SET LAYOUT for the first Page on Playground
let page_layout = pages.firstPage.layout

fillPlaygroundPage(projectsFirstPageFiltered, page_layout)

function fillPlaygroundPage(loadedProjects, layout) {
  let prevElements = document.querySelectorAll(".playground-element")
  if (prevElements) {
    prevElements.forEach((el) => {
      el.remove()
    })
  }

  // set or change page_layout
  if (playground.classList[2]) {
    let h = playground.classList[2]
    playground.classList.remove(h)
    playground.classList.add(`page_layout_${layout}`)
  } else {
    playground.classList.add(`page_layout_${layout}`)
  }

  let i = 1
  // Set image pairs on playground
  let arr = [1, 2, 4, 5, 7, 8, 10, 11, 13, 14, 16]
  let playgroundElement
  loadedProjects.forEach((project) => {
    if (arr.includes(i)) {
      playgroundElement = document.createElement("div")
      playgroundElement.classList.add("playground-element")
      playgroundElement.classList.add(`playground-element-${i}`)
    }
    let data = getSingleElement(project)
    // console.log(data)
    let c = document.createElement("div")
    let meta = document.createElement("div")

    c.classList.add("element")
    meta.classList.add("element-hover")
    meta.innerHTML = data.meta
    c.innerHTML = data.output
    c.dataset.id = data.projectId
    c.appendChild(meta)
    playgroundElement.appendChild(c)

    if (arr.includes(i)) {
      playground.appendChild(playgroundElement)
    }

    // SET GALLERY EVENT LISTENER
    c.addEventListener("click", function (e) {
      openProject(e, c.dataset.id)
    })
    i++
  })
}

async function changePage() {
  let pageID = this.dataset.pageId

  document.querySelectorAll(".page-link").forEach((link) => {
    link.dataset.activePage = false
  })
  console.log(this)
  this.dataset.activePage = true
  let o = {
    id: pageID,
  }
  let p = await getProjects(o)

  let page = pages.pages.filter((p) => {
    return p.id == pageID
  })
  let page_layout = page[0].layout

  let p_filtered = p.filter((project) => {
    return project.status == true
  })

  fillPlaygroundPage(p_filtered, page_layout)
  zenscroll.to(playground)
}

let body = document.getElementById("home")
let back = document.querySelector(".back")
let project__infos = document.querySelector(".project__infos")
let project__infos_icon = document.querySelector(".project__infos-icon")
let project__infos__container = document.querySelector(
  ".project__infos__container"
)

let gallery

async function openProject(e, projectID) {
  let d = await getProject(projectID)
  let singleProject = document.querySelector(".single__project")
  let singleProjectNav = document.querySelector(".single__project-navigation")
  singleProject.style.background = setProjectBG(d.bg)

  // console.log(d)
  getProjectElements(d)

  project__infos.innerHTML = d.description

  // initialize Slider Functions
  let slider = document.querySelector(".single__project__wrapper")
  gallery = new Flickity(slider, {
    // options
    cellAlign: "center",
    wrapAround: true,
    imagesLoaded: true,
    lazyLoad: true,
    prevNextButtons: false,
    pageDots: false,
    setGallerySize: true,
    cellSelector: ".single__element",
  })

  document.querySelector(".single__project").classList.add("show")
  back.addEventListener("click", backToPlayground, false)

  body.classList.add("noscroll")
  window.setTimeout(() => {
    back.classList.add("back-show")
    project__infos__container.classList.add("project__infos-icon-show")
    project__infos_icon.addEventListener("click", showProjectInfos)
  }, 500)

  // gallery.reloadCells()

  // CHANGE ON MOBILE
  if (mobile__view) {
    console.log(singleProject)
    let mc = new Hammer(singleProject)
    mc.get("swipe").set({ direction: Hammer.DIRECTION_ALL })

    mc.on("swipedown", function (ev) {
      //console.log(ev)
      backToPlayground()
    })
  } else {
    // create Navigation
    let ctrlLeft = document.createElement("div")
    ctrlLeft.classList.add("gallery-left")
    singleProjectNav.appendChild(ctrlLeft)
    let ctrlRight = document.createElement("div")
    ctrlRight.classList.add("gallery-right")
    singleProjectNav.appendChild(ctrlRight)

    ctrlLeft.addEventListener("click", () => {
      gallery.previous()
    })
    ctrlRight.addEventListener("click", () => {
      gallery.next()
    })
  }

  // GET ELEMENT
  let selectedElement
  let selectedElementPrev =
    document.querySelector(".is-selected").children[0].children[0]
  if (!selectedElementPrev) {
    selectedElementPrev = document.querySelector(".is-selected").children[0]
  }
  console.log(selectedElementPrev)

  // Pause Video on Gallery Change!
  gallery.on("change", function (index) {
    if (selectedElementPrev && selectedElementPrev.tagName == "VIDEO") {
      selectedElementPrev.pause()
      selectedElement =
        document.querySelector(".is-selected").children[0].children[0]
      if (!selectedElement) {
        selectedElement = document.querySelector(".is-selected").children[0]
      }
    } else {
      if (selectedElementPrev.tagName == "VIDEO") {
        // CASE VIDEO
        selectedElement =
          document.querySelector(".is-selected").children[0].children[0]
      } else if (selectedElementPrev.tagName == "IMG") {
        // CASE PHOTO
        selectedElement = document.querySelector(".is-selected").children[0]
        if (selectedElement.tagName == "DIV") {
          selectedElement =
            document.querySelector(".is-selected").children[0].children[0]
        }
      } else {
        selectedElement = document.querySelector(".is-selected").children[0]
      }
    }
    selectedElementPrev = selectedElement
  })
}

function backToPlayground() {
  let content = document.querySelector(".single__project__wrapper")

  document.querySelector(".single__project-navigation").innerHTML = ""

  document.querySelector(".single__project").classList.remove("show")
  window.setTimeout(() => {
    document.querySelector(".single__project").scrollTo(top)
    gallery.destroy()
    content.innerHTML = ""
  }, 300)
  body.classList.remove("noscroll")
  back.classList.remove("back-show")
  project__infos__container.classList.remove("project__infos-icon-show")
}

let about = document.querySelector(".about__wrapper")
let aboutBtn = document.querySelector(".show__about-btn")
let aboutClose = document.querySelector(".about-close")

let impprintBtn = document.querySelector(".imprint__btn")
let imprint = document.querySelector(".imprint__wrapper")
let dsgvo = document.getElementById("dsgvo")
let imprintClose = document.querySelector(".imprint-close")
let dsgvoBtn = document.querySelector(".dsgvo__btn")
let dsgvoClose = document.querySelector(".close-dsgvo")
let scroller = imprint
let dsgvo_scroller = zenscroll.createScroller(scroller, 500, 0)

impprintBtn.addEventListener("click", showImprint)
dsgvoBtn.addEventListener("click", showDsgvo)

aboutBtn.addEventListener("click", showAbout)

function showAbout() {
  console.log("show about")
  about.classList.add("about-show")
  body.classList.add("noscroll")
  aboutClose.addEventListener("click", closeAbout)
}

function closeAbout() {
  about.classList.remove("about-show")
  body.classList.remove("noscroll")
}

function showProjectInfos() {
  project__infos.classList.toggle("project__infos-show")
}

function showImprint() {
  console.log("show Imprint")
  imprint.classList.add("imprint-show")
  body.classList.add("noscroll")
  imprintClose.addEventListener("click", closeImprint)
  dsgvoClose.addEventListener("click", closeImprint)
}

function showDsgvo() {
  console.log("show DSGVO")
  imprint.classList.add("imprint-show")

  body.classList.add("noscroll")
  window.setTimeout(function () {
    dsgvo_scroller.to(dsgvo)
  }, 600)

  imprintClose.addEventListener("click", closeImprint)
  dsgvoClose.addEventListener("click", closeImprint)
}

function closeImprint() {
  imprint.classList.remove("imprint-show")
  body.classList.remove("noscroll")
  dsgvo_scroller.toY(0)
}
