import variables from "./variables"
const axios = require("axios")

async function getProjects(page) {
  const data = variables()
  let id
  if (typeof page == "object") {
    id = page.id
  } else {
    id = page
  }

  let url = data.projectURL + "&page=" + id
  //console.log(url)
  //Get Projects of first Page
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export default getProjects
