import variables from "./variables"
const axios = require("axios")

async function getProject(id) {
  const data = variables()

  let url = data.projectPageURL + id
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export default getProject
